.field,
.shortField,
.sliderField {
  display: flex;
  align-items: center;
  min-width: 142px;
}

.sliderField .elementWrapper:first-child {
  width: 70%;
}

.elementWrapper {
  margin-right: 6px;
}

.elementWrapper:last-child {
  margin-right: 0;
  flex: 1 100%;
}

.shortField .elementWrapper:last-child {
  margin-right: 0;
  flex: none;
}

.input {
  margin-bottom: 8px;
  max-width: 76px;
}

.select {
  padding: 7px 24px 7px 16px;
  min-width: 76px;
}
