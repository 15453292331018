.rte :global .tox-tinymce {
  border: none !important;
  border-radius: 0 !important;
}

.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: 12px;
}

.rte {
  width: 100%;
  position: relative;
}

.shortCodesButtonWrapper {
  top: 100px;
  z-index: 2;
  position: absolute;
}

.buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
}

.shortCodeButton,
.shortCodesButtonWrapper button {
  border-radius: 5px !important;
  border: 1px solid var(--new-gray-6) !important;
  background: var(--new-gray-6) !important;
  color: #000000 !important;
  font-weight: 600 !important;
  margin-bottom: 8px;
}

.shortCodesButtonWrapper .button {
  color: var(--game-blue) !important;
  border: 1px solid var(--game-blue) !important;
}

.shortCodeButton {
  margin-right: 5px;
}

.shortCodeButton.active {
  background: var(--game-blue) !important;
  color: #ffffff !important;
}

.codeHint {
  font-size: 14px !important;
  max-width: 400px;
  word-wrap: break-word;
  margin-top: 8px;
}

.rte :global .tox .tox-toolbar__group {
  height: var(--rte-btn-height) !important;
  margin-right: 6px !important;
  margin-bottom: 6px !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 0 !important;
  box-sizing: content-box;
}

.rte.shortCodes :global .tox .tox-editor-header {
  margin-bottom: 20px !important;
}

.rte :global .tox .tox-split-button {
  height: var(--rte-btn-height) !important;
  margin: 0 !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 0 !important;
  box-sizing: content-box;
}

.rte :global .tox .tox-toolbar__group:last-child {
  margin-right: 0 !important;
}

.rte :global .tox .tox-tbtn {
  background: var(--new-gray-6);
  height: 26px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border-top: 1px solid var(--new-gray-6) !important;
  border-bottom: 1px solid var(--new-gray-6) !important;
  border-right: 1px solid var(--new-gray-6) !important;
  padding: 0 !important;
}

.rte :global .tox .tox-tbtn:first-child {
  border-radius: 5px 0 0 5px !important;
  border: 1px solid var(--new-gray-6) !important;
}
.rte :global .tox .tox-tbtn:last-child {
  border-radius: 0 5px 5px 0 !important;
  border: 1px solid var(--new-gray-6) !important;
  border-left: none !important;
}

.rte :global .tox .tox-tbtn:only-child {
  border-radius: 5px !important;
  border: 1px solid var(--new-gray-6) !important;
}

.rte :global .tox .tox-split-button__chevron {
  border-radius: 0 5px 5px 0 !important;
}

.rte :global .tox .tox-tbtn:hover.rte :global .tox .tox-tbtn:focus {
  background: none !important;
  color: var(--game-blue) !important;
}

.rte :global .tox .tox-tbtn--enabled.rte :global .tox .tox-tbtn--enabled:hover {
  background: var(--game-blue) !important;
}

.rte :global .tox .tox-tbtn:hover {
  background: var(--game-blue) !important;
}
.rte :global .tox .tox-tbtn:hover svg {
  fill: var(--snow) !important;
}
.rte :global .tox .tox-tbtn--enabled svg.rte :global .tox .tox-tbtn--enabled:hover svg {
  fill: var(--snow) !important;
}
.rte :global .tox .tox-tbtn--bespoke:hover .tox-tbtn__select-label {
  color: var(--snow) !important;
}

.rte :global .tox .tox-toolbar.rte :global .tox .tox-toolbar__overflow.rte :global .tox .tox-toolbar__primary {
  background-image: none !important;
}

.rte :global .tox .tox-edit-area__iframe {
  background: var(--new-gray-6);
}

.rte :global .tox .tox-sidebar-wrap {
  border: 1px solid var(--new-gray-6) !important;
  border-radius: 8px 8px 0 0 !important;
  margin-top: 24px;
}

.rte :global .tox .tox-split-button .tox-tbtn.rte :global .tox .tox-split-button {
  height: var(--rte-btn-height) !important;
}

.rte :global .tox .tox-edit-area {
  border-radius: 8px 8px 0 0 !important;
}

.rte :global .tox .tox-statusbar {
  border: 1px solid var(--new-gray-6) !important;
  border-top: none !important;
  background: var(--new-gray-6);
  border-radius: 0 0 8px 8px !important;
}

.rte :global .tox .tox-dialog {
  border-radius: 8px !important;
  border: none !important;
}

.rte :global .tox .tox-dialog .tox-dialog__footer {
  border: none;
}

.rte :global .tox .tox-dialog .tox-dialog__footer-end .tox-button {
  border-radius: 15px !important;
  background: var(--game-blue);
}

.rte :global .tox .tox-dialog .tox-dialog__footer-end .tox-button.tox-button--secondary {
  border-radius: 15px !important;
  background: var(--snow);
  color: var(--game-blue);
  border-color: var(--game-blue);
}
