.wrapper {
  display: flex;
  position: relative;
  min-width: 160px;
}

.listWrapper {
  position: absolute;
  top: 100%;
  min-width: 100%;
  right: 0;
  z-index: 2;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 5px;
  background: var(--snow);
  border: 1px solid #e2e2ea;
  border-radius: 5px;
  filter: drop-shadow(0px 8px 16px rgba(67, 86, 100, 0.02)) drop-shadow(0px 16px 32px rgba(67, 86, 100, 0.16));
}

.list {
  padding: 10px 6px;
  margin: 0;
  list-style: none;
  max-width: 200px;
}

.list li {
  margin-bottom: 2px;
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  border-radius: 6px;
  padding: 6px 10px;
}

.itemBody {
  flex-wrap: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.active {
  border-radius: 5px;
  padding: 10px 16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
}

.active.select {
  border: 1px solid var(--new-light-gray);
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  padding: 10px 30px 10px 16px;
}

.active.select.open {
  border: 1px solid var(--game-blue);
}

.active.select.open .caret {
  transform: translateY(-50%) rotateZ(180deg);
}

.caret {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}

.active.disabled {
  cursor: not-allowed;
}

.item.activeItem {
  background: #ebf0fe;
  color: var(--game-blue);
}

.icon {
  margin-right: 12px;
}

.label {
  color: var(--night);
  font-size: 14px;
}

.item.hoverable:hover {
  background: var(--game-blue);
}

.item.hoverable:hover .itemLabel {
  color: var(--snow);
}

.itemLabel {
  white-space: nowrap;
}

.divider {
  position: relative;
  margin-top: 4px;
}

.divider:before {
  display: inline-block;
  content: '';
  top: -2.5px;
  left: 5px;
  width: calc(100% - 10px);
  height: 1px;
  background: #ededed;
  position: absolute;
}

.option {
  margin-left: 14px;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  border-radius: 6px;
  padding: 6px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
