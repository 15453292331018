.wrapper {
  display: flex;
  flex-direction: column;
  width: auto;
}

.label {
  color: var(--night);
  font-weight: 500 !important;
  line-height: 19px !important;
  letter-spacing: -0.02em !important;
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.radio.disabledRadio {
  cursor: not-allowed;
}

.radio.disabledRadio .label {
  color: var(--new-dark-gray);
}

.radioWrapper {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

.radioWrapper input {
  display: none;
}

.spot:after {
  content: '';
  width: 14px;
  height: 14px;
  display: inline-block;
  transition:
    width ease 0.2s,
    height ease 0.2s;
  border-radius: 50%;
  background: var(--snow);
}

.spot {
  width: 18px;
  position: relative;
  transition: background ease 0.2s;
  background: var(--gray-300);
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spot.checked {
  background: var(--game-blue);
}

.spot.checked:after {
  width: 6px;
  height: 6px;
}

.spot.disabled,
.spot.checked.disabled {
  background: var(--gray-200);
}

.spot.checked.disabled:after {
  background: var(--gray-300);
}

.spot.checked.disabled:before {
  display: inline-block;
}

.spot:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: none;
  border: 2px solid var(--gray-300);
  border-radius: 50%;
}

.spot.checked:after {
  width: 6px;
  height: 6px;
}
