.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.buttonsWrapper .btn {
  margin-left: 8px;
}

.hint {
  margin-bottom: 10px;
}

.modalOverlay {
  justify-content: flex-end;
  padding-right: 20px;
  background: transparent;
  box-shadow: 0px 16px 22px 0px #0000001a;
}

.modal {
  width: 650px !important;
  min-width: 650px !important;
}

.input {
  margin-bottom: 30px !important;
}

.input label {
  word-break: break-all;
  white-space: unset;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.textarea textarea {
  margin: 15px 30px 0;
  width: unset !important;
}
