.togglerWrapper {
  display: flex;
  pointer-events: auto;
}

.checkbox {
  display: none;
}

.spotWrapper {
  display: flex;
  width: 38px;
  height: 22px;
  border-radius: 15px;
  background: var(--new-gray-7);
  position: relative;
  transition: background var(--animation);
  cursor: pointer;
}

.spotWrapper.checkedWithText {
  padding-right: 23px;
  width: auto;
  padding-left: 6px;
  color: var(--snow);
}

.spotWrapper.unCheckedWithText {
  padding-left: 23px;
  width: auto;
  padding-right: 6px;
}

.checkbox:checked + .spotWrapper {
  background: var(--game-blue);
}

.checkbox:checked + .spotWrapper .spot {
  right: 2px;
}

.spotWrapper.badGood .checkbox:checked + .spotWrapper {
  background: var(--snow);
}

.spotWrapper.badGood.bad {
  background: var(--new-red);
}

.spotWrapper.badGood.good {
  background: var(--new-green-500);
}

.checkbox[disabled] + .spotWrapper {
  cursor: not-allowed;
  background: var(--new-gray-4);
}

.checkbox[disabled] + .spotWrapper .spot {
  background: var(--new-gray-5);
}

.spot {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--snow);
  top: 50%;
  transition:
    right var(--animation),
    background var(--animation);
  right: calc(100% - 20px);
  position: absolute;
  transform: translateY(-50%);
}

.wrapper {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  pointer-events: none;
}

.wrapper.sb {
  justify-content: space-between;
}

.errorsLabelWrapper {
  margin-right: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.label {
  font-size: 14px;
  color: var(--night);
  font-family: 'Inter', sans-serif;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 16px;
}

.errors {
  color: var(--new-red);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
}

.typo {
  height: 40px;
  display: flex;
  align-items: center;
}

.inline {
  margin-bottom: 8px;
}

.alignLabelRight {
  text-align: right;
}

.inline .label {
  margin-right: 6px;
  font-weight: 400;
}
