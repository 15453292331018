.wrapper {
  background: var(--gray-file-upload);
  width: 100%;
  min-height: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  text-align: center;
  margin-top: 8px;
}

.wrapper.onlyTitle .title {
  margin-bottom: 0;
}

.wrapper.white {
  background: var(--new-gray-4);
  border-radius: 6px;
  margin-top: 0;
}

.wrapper.white .title,
.wrapper.white .subtitle {
  max-width: 250px;
  width: 100%;
}

.title {
  margin-bottom: 16px;
  max-width: 180px;
  width: 100%;
}

.subtitle {
  max-width: 180px;
  width: 100%;
}
