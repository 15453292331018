.option {
  margin-left: 14px;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  border-radius: 6px;
  padding: 6px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.active {
  background: var(--game-blue);
  color: var(--snow);
}

.separator {
  width: 100%;
  border: 1px solid var(--light-gray-border);
  border-radius: 1px;
  margin: 4px 4px;
}
