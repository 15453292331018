.select {
  position: relative;
  width: 100%;
}

.listWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  min-width: 100%;
  z-index: 3;
  display: none;
  filter: var(--filter-shadow);
  background: var(--snow);
  border: 1px solid var(--new-light-gray-2);
  border-radius: 5px;
}

.clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
}

.select.active .listWrapper {
  display: flex;
}

.listWrapper ul {
  padding: 15px 6px;
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 0;
}

.listWrapper ul li {
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--night);
  white-space: nowrap;
  margin-bottom: 6px;
  font-family: 'Inter', sans-serif;
}

.listWrapper ul li:hover:not(.empty) {
  color: var(--snow);
  background: var(--game-blue);
}

.search {
  border: 1px solid var(--new-gray-6);
  background: var(--new-gray-6);
  box-shadow: none;
  cursor: pointer;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 26px 7px 30px;
  align-items: center;
  display: flex;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.search.tiny {
  height: 30px;
}

.search.withoutIcon {
  padding: 7px 16px;
}

.search::placeholder {
  color: var(--new-gray-3);
}

.searchWrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 8px;
  width: 16px;
  transform: translateY(-50%);
  height: 16px;
}

.placeholder {
  color: var(--new-gray-3);
}

.search:focus {
  border: 1px solid var(--new-blue);
}

.search.hasError,
.search.hasError:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.search[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.search[disabled]::placeholder {
  color: var(--new-gray-5);
}
