.select {
  position: relative;
}

.listWrapper {
  position: absolute;
  top: 40px;
  left: 0;
  margin-top: 4px;
  width: 100%;
  z-index: 2;
  display: none;
  filter: var(--filter-shadow);
  background: var(--snow);
  border: 1px solid var(--new-light-gray-2);
  border-radius: 5px;
}

.closeIcon {
  display: flex;
  cursor: pointer;
  margin-left: 4px;
  width: 12px;
  height: 12px;
}

.select.active .listWrapper {
  display: flex;
}

.listWrapper ul {
  padding: 15px 6px;
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 0;
}

.listWrapper ul li {
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--night);
  margin-bottom: 6px;
  font-family: 'Inter', sans-serif;
}

.listWrapper ul li:hover:not(.empty) {
  color: var(--snow);
  background: var(--game-blue);
}

.search {
  border: 1px solid var(--new-gray-6);
  background: var(--new-gray-6);
  box-shadow: none;
  cursor: pointer;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px 7px 46px;
  align-items: center;
  display: flex;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.search::placeholder {
  color: var(--new-gray-3);
}

.searchWrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 16px;
  width: 16px;
  transform: translateY(-50%);
  height: 16px;
}

.placeholder {
  color: var(--new-gray-3);
}

.search:focus {
  border: 1px solid var(--new-blue);
}

.search.hasError,
.search.hasError:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.search[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.search[disabled]::placeholder {
  color: var(--new-gray-5);
}

.tagsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.tagsWrapper.bottom {
  margin-top: 4px;
}

.tag {
  display: flex;
  align-items: center;
  border-radius: 40px;
  /*padding: 4px 12px 4px 4px;*/
  padding: 4px 8px;
  max-height: 26px;
  white-space: nowrap;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 9px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  justify-content: space-between;
  background: var(--game-blue);
  color: var(--snow);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--game-blue);
  margin: 0 4px 4px 0;
  position: relative;
}

.tag.md {
  font-size: 12px;
  margin: 0 6px 6px 0;
  padding: 6px 10px;
  max-height: 32px;
}

.tagIcon img {
  width: 10px;
  height: 10px;
}

.tagIcon {
  display: flex;
  position: absolute;
  top: 50%;
  right: 2px;
  width: 10px;
  transform: translateY(-50%);
}
