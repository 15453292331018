.overlay {
  position: fixed;
  top: 0;
  z-index: 12;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 12, 49, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  margin-bottom: 20px;
}

.content {
  position: relative;
  display: flex;
  width: 550px;
  background: var(--snow);
  box-shadow:
    0 16px 22px rgba(0, 0, 0, 0.1),
    0 34px 55px rgba(0, 0, 0, 0.05),
    0 13px 66px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 36px 0;
  flex-direction: column;
}

.hint {
  padding: 0 25px;
}

:global .ReactModal__Body--open {
  overflow: hidden;
}

.content.wide {
  width: 800px;
}

.content.flexible {
  width: auto;
  max-width: 90vw;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 4px;
  padding-right: 30px;
  padding-left: 25px;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--gray-500);
  margin-bottom: 6px;
  padding-right: 30px;
  padding-left: 25px;
}

.contentWrapper {
  margin-top: 16px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 0 25px;
}

.cancelIcon {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 2;
}

.cancelIcon.withoutTitle {
  top: 12px;
  right: 12px;
}
