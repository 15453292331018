.field,
.shortField,
.sliderField {
  display: flex;
  align-items: center;
}

.sliderField .elementWrapper:first-child {
  width: 70%;
}

.elementWrapper {
  margin-right: 6px;
}

.elementWrapper:last-child {
  margin-right: 0;
  flex: 1 100%;
}

.shortField .elementWrapper:last-child {
  margin-right: 0;
  flex: none;
}
