.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.inputs {
  display: flex;
  margin-bottom: var(--input-padding);
}

.input {
  margin-right: 8px;
  margin-bottom: 0;
}

.tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.tag {
  padding: 1px 16px 1px 9px;
  background: var(--snow);
  position: relative;
  border: 1px solid var(--border-gray);
  border-radius: 20px;
  font-size: var(--input-fsz);
  color: var(--black);
  margin: 0 var(--input-padding) var(--input-padding) 0;
}

.removeTag {
  color: var(--dark-gray);
  font-size: 10px;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 0;
}

.tag:last-child {
  margin-right: 0;
}

.addBtn {
  height: var(--input-height) !important;
}
