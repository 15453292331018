.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  right: 0;
  bottom: 0;
  z-index: 1010;
  background: rgba(16, 12, 49, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: opacity-on 0.2s;
}

.modal {
  width: 560px;
  min-height: 300px;
  position: relative;
  display: flex;
  flex-direction: row;
  background: var(--snow);
  border-radius: 12px;
  animation: scale-up-center 0.2s;
  box-shadow:
    0 16px 22px rgba(0, 0, 0, 0.1),
    0 34px 55px rgba(0, 0, 0, 0.05),
    0 13px 66px rgba(0, 0, 0, 0.07);
}

.wrapper.closing .modal {
  animation: scale-down-center 0.2s;
  animation-fill-mode: forwards;
}

.wrapper.closing {
  animation: opacity-off 0.2s;
  animation-fill-mode: forwards;
}

.cancelBtn {
  margin-top: 16px;
}

.btnsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  width: 50%;
  padding: 48px 0 48px 32px;
  display: flex;
  flex-direction: column;
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: var(--gray-350);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  padding: 0;
}

.close:hover {
  background: var(--gray-300);
}

.addy {
  position: absolute;
  bottom: -15px;
  pointer-events: none;
  right: 0;
}

.ground {
  position: absolute;
  pointer-events: none;
  bottom: 0;
  right: 0;
  border-radius: 0 0 12px 0;
}

.title {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 32px;
  line-height: 28px;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes opacity-on {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-off {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
