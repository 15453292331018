.modal {
  width: 1100px !important;
}

.mediaList {
  display: flex;
  flex-wrap: wrap;
}

.mediaList .item {
  padding: 8px;
}

.imageWrapper {
  border-radius: 8px;
  overflow: hidden;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.image {
  width: 100%;
  height: 100%;
}

.imageWrapper.active {
  border: 1px solid var(--game-blue);
}

.footerWrapper {
  width: 100%;
  justify-content: center;
  display: flex;
}

.popoverhint {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 500;
}

.footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--light-gray-border);
  width: 100%;
  padding-top: 20px;
}

.editableBtn {
  width: 100%;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background var(--animation);
  border: none;
  height: 25px;
}

.editableBtn.small {
  height: 20px;
}

.editableBtn.small .text {
  font-size: 10px !important;
}

.trashBtn {
  opacity: 0;
  position: absolute;
  top: 2px;
  right: 2px;
  transition: all 0.2s;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 2px;
  background: rgba(255, 255, 255, 0.75);
}

.hover .trashBtn {
  opacity: 1;
}

.trashBtn:hover {
  background: rgba(255, 255, 255, 1);
}
.trashIcon {
  width: 18px;
  height: 18px;
}

.trashBtn.small .trashIcon {
  width: 16px;
  height: 16px;
}

.editableBtn:hover {
  background: var(--snow);
}

.cropperFooter {
  margin-top: 10px;
}

.cropperFooter .footer button + button {
  margin-left: 5px;
}

.footer button {
  margin-top: 16px !important;
  width: 200px !important;
}

.modalTitle {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -1.6px;
  margin-top: 32px;
  font-family: 'Inter', serif;
}

.tabsWrapper {
  display: flex;
  margin-bottom: 36px;
}

.tab {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border-gray);
}

.tab .label {
  font-size: 16px;
  line-height: 110%;
  color: var(--gray-500);
}

.tab .tabIcon {
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.tab .tabIcon svg {
  fill: var(--gray-500);
}

.tab.active .tabIcon svg {
  fill: var(--game-blue);
}

.tab.active p {
  color: #040415;
}

.tab.active {
  border-bottom: 2px solid var(--game-blue);
}

.tab.disabled {
  cursor: not-allowed;
}

.tab.disabled .label,
.tab.disabled .tabIcon {
  color: var(--gray-200);
}

.actionsWrapper {
  display: flex;
}
.action {
  color: var(--game-blue) !important;
  background: var(--snow) !important;
  border: 1px solid var(--light-gray-border) !important;
  width: 120px !important;
  height: 32px !important;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.action:first-child {
  border-radius: 16px 0 0 16px !important;
  border-right: 0 !important;
}

.action:last-child {
  border-left: 0 !important;
  border-radius: 0 16px 16px 0 !important;
}

.action.active {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.removeBgBtn {
  margin: 32px auto 0;
}

.uploader {
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid var(--new-gray-6);
  display: inline-block;
  overflow: hidden;
}

.uploader.big {
  width: 92px;
  height: 92px;
  min-width: 92px;
  min-height: 92px;
  max-width: 92px;
  max-height: 92px;
}

.uploader.disabled,
.uploader.empty.disabled,
.uploader.empty.disabled:hover,
.uploader.empty.disabled:focus,
.uploader.empty.disabled:active {
  cursor: not-allowed;
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
  box-shadow: none;
}

.uploader.empty {
  color: var(--game-blue);
  display: flex;
  background: var(--pastel-blue);
  border: 1px dashed var(--light-blue);
}

.uploader.empty:hover {
  border: 1px solid var(--game-blue);
}

.uploader.empty[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
}

.uploader.empty:focus,
.uploader.empty:active {
  background: var(--pastel-blue);
  border: 1px solid var(--game-blue);
}

.uploaderButtonBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.uploaderButtonIcon {
  font-size: 16px;
  color: inherit;
  margin-bottom: var(--input-padding);
}

.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.inputFileBtn {
  margin-top: 4px;
  position: relative !important;
  cursor: pointer !important;
}

.uploader .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  background-image: url('../../../images/icons/pngbackground.png');
  background-size: 100px 100px;
}

.dndArea {
  width: 560px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  margin: auto auto 24px auto;
  background: var(--pastel-blue);
  border: 2px dashed var(--light-blue);
}

.dndArea p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.modalWrapper {
}

.dndArea.canDrop {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.iconWrapper {
  font-size: 32px;
  color: var(--game-blue);
}

.sidesWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  justify-content: center;
}

.sidesWrapper + .footerWrapper {
  margin-top: 24px;
}

.imageWrapper {
  display: flex;
  margin: 0 auto;
  width: fit-content;
  height: 100%;
}

.cropImageWrapper {
  height: max-content;
  width: max-content;
  position: relative;
  overflow: hidden;
}

.imagePreview {
  max-height: 400px;
  object-fit: contain;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
  margin: auto;
}

.cropWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  user-select: none;
  align-items: center;
  width: 100%;
}

.cropBox {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px dashed var(--game-blue);
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.4);
}

.cropPoint {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  margin-top: -5px;
  margin-left: -5px;
}

.backgroundEditorWrapper {
  width: 100%;
  display: flex;
}

.editorCanvas {
  background-image: url('../../../images/icons/pngbackground.png');
  background-size: 100px 100px;
}

.leftSide {
  display: flex;
  justify-content: center;
  max-width: 400px;
  height: 400px;
  width: 100%;
}

.rightSide {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageSizesWrapper {
  position: relative;
  height: fit-content;
}

.chainIcon {
  font-size: 18px;
  color: var(--gray-font);
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.removableWrapper.hover .buttonsImageWrapper {
  transform: translateY(-100%);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0);
}

.removableWrapper.hover .overlay {
  background: rgba(0, 0, 0, 0.2);
}

.buttonsImageWrapper {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  height: 25px;
  align-items: flex-end;
  transition: all 0.2s;
}

.buttonsImageWrapper.small {
  height: 20px;
  bottom: -20px;
}

.btnsWrapper {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerBtn {
  margin-left: 5px;
}

.btnsWrapper button:not(:last-child) {
  margin-right: 8px;
}

.arrow {
  margin-right: 5px;
}

.btnArrow {
  height: 30px !important;
  width: 50% !important;
  border: none !important;
  font-size: 14px !important;
  box-shadow: none !important;
  background: none !important;
  color: var(--game-blue) !important;
}

.btnArrow span {
  border-bottom: 1px solid var(--game-blue) !important;
}

.applyBtn {
  max-height: 40px !important;
  height: 40px !important;
  width: 50% !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.canvasWrapper {
  margin: auto;
}

.modalConfirmText {
  margin-bottom: 8px;
}

.addyThink {
  background: url('../../../images/addythink.png') center top no-repeat;
  background-size: cover;
  width: 94px;
  height: 177px;
}

.busyWrapper {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.busyWrapper .hint {
  margin-top: 24px;
  font-family: 'Roboto', 'serif';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
}
