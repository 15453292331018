:global .tox-tinymce {
  border: none !important;
  border-radius: 0 !important;
}

.rte {
  width: 100%;
}

:global .tox .tox-toolbar__group {
  height: var(--rte-btn-height) !important;
  margin-right: 6px !important;
  margin-bottom: 6px !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 0 !important;
  box-sizing: content-box;
}

:global .tox .tox-split-button {
  height: var(--rte-btn-height) !important;
  margin: 0 !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 0 !important;
  box-sizing: content-box;
}

:global .tox .tox-toolbar__group:last-child {
  margin-right: 0 !important;
}

:global .tox .tox-tbtn {
  height: 26px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border-top: 1px solid var(--gray-input-preview) !important;
  border-bottom: 1px solid var(--gray-input-preview) !important;
  border-right: 1px solid var(--gray-input-preview) !important;
  padding: 0 !important;
}

:global .tox .tox-tbtn:first-child {
  border-radius: 8px 0 0 8px !important;
  border: 1px solid var(--gray-input-preview) !important;
}
:global .tox .tox-tbtn:last-child {
  border-radius: 0 8px 8px 0 !important;
  border: 1px solid var(--gray-input-preview) !important;
  border-left: none !important;
}

:global .tox .tox-tbtn:only-child {
  border-radius: 8px !important;
  border: 1px solid var(--gray-input-preview) !important;
}

:global .tox .tox-split-button__chevron {
  border-radius: 0 8px 8px 0 !important;
}

:global .tox .tox-tbtn:hover,
:global .tox .tox-tbtn:focus {
  background: none !important;
  color: var(--game-blue) !important;
}

:global .tox .tox-tbtn--enabled,
:global .tox .tox-tbtn--enabled:hover {
  background: var(--game-blue) !important;
}

:global .tox .tox-tbtn:hover {
  background: var(--game-blue) !important;
}
:global .tox .tox-tbtn:hover svg {
  fill: var(--snow) !important;
}
:global .tox .tox-tbtn--enabled svg,
:global .tox .tox-tbtn--enabled:hover svg {
  fill: var(--snow) !important;
}
:global .tox .tox-tbtn--bespoke:hover .tox-tbtn__select-label {
  color: var(--snow) !important;
}

:global .tox .tox-toolbar,
:global .tox .tox-toolbar__overflow,
:global .tox .tox-toolbar__primary {
  background-image: none !important;
}

:global .tox .tox-sidebar-wrap {
  border: 1px solid var(--gray-input-preview) !important;
  border-radius: 10px 10px 0 0 !important;
}

:global .tox .tox-split-button .tox-tbtn,
:global .tox .tox-split-button {
  height: var(--rte-btn-height) !important;
}

:global .tox .tox-edit-area {
  border-radius: 10px 10px 0 0 !important;
}

:global .tox .tox-statusbar {
  border: 1px solid var(--gray-input-preview) !important;
  border-top: none !important;
  border-radius: 0 0 10px 10px !important;
}

:global .tox .tox-dialog {
  border-radius: 8px !important;
  border: none !important;
}

:global .tox .tox-dialog .tox-dialog__footer {
  border: none;
}

:global .tox .tox-dialog .tox-dialog__footer-end .tox-button {
  border-radius: 15px !important;
  background: var(--game-blue);
}

:global .tox .tox-dialog .tox-dialog__footer-end .tox-button.tox-button--secondary {
  border-radius: 15px !important;
  background: var(--snow);
  color: var(--game-blue);
  border-color: var(--game-blue);
}
