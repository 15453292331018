.modal {
  width: 1000px !important;
}

.title {
}

.footerWrapper {
  width: 100%;
  justify-content: center;
  display: flex;
}

.footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--light-gray-border);
  width: 90%;
  padding-top: 40px;
  margin-bottom: 24px;
}

.cropperFooter {
  margin-top: 10px;
}

.cropperFooter .footer button + button {
  margin-left: 5px;
}

.footer button {
  margin-top: 16px !important;
  width: 200px !important;
}

.modalTitle {
  font-weight: 500;
  font-size: 32px;
  letter-spacing: -1.6px;
  margin-top: 32px;
  font-family: 'Inter', serif;
}

.tabsWrapper {
  display: flex;
  margin: 36px 0 36px;
}

.tab {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border-gray);
}

.tab .label {
  font-size: 16px;
  line-height: 110%;
  color: var(--gray-500);
}

.tab .tabIcon {
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.tab .tabIcon svg {
  fill: var(--gray-500);
}

.tab.active .tabIcon svg {
  fill: var(--game-blue);
}

.tab.active p {
  color: #040415;
}

.tab.active {
  border-bottom: 2px solid var(--game-blue);
}

.tab.disabled {
  cursor: not-allowed;
}

.tab.disabled .label,
.tab.disabled .tabIcon {
  color: var(--gray-200);
}

.sizesInput * {
  color: var(--cobalt) !important;
}
.sizesInput input {
  background: var(--snow) !important;
  border: 1px solid var(--gray-input-preview) !important;
}

.actionsWrapper {
  display: flex;
}
.action {
  color: var(--game-blue) !important;
  background: var(--snow) !important;
  border: 1px solid var(--light-gray-border) !important;
  width: 120px !important;
  height: 32px !important;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.action:first-child {
  border-radius: 16px 0 0 16px !important;
  border-right: 0 !important;
}

.action:last-child {
  border-left: 0 !important;
  border-radius: 0 16px 16px 0 !important;
}

.action.active {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.removeBgBtn {
  margin: 32px auto 0 auto;
  width: 200px;
}

.uploader {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid var(--border-gray);
  display: inline-block;
}

.uploader.disabled,
.uploader.empty.disabled,
.uploader.empty.disabled:hover,
.uploader.empty.disabled:focus,
.uploader.empty.disabled:active {
  cursor: not-allowed;
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
  box-shadow: none;
}

.uploader.empty {
  color: var(--snow);
  display: flex;
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
}

.uploader.empty:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.uploader.empty[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
}

.uploader.empty:focus,
.uploader.empty:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.uploaderButtonBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.uploaderButtonIcon {
  font-size: 16px;
  color: inherit;
  margin-bottom: var(--input-padding);
}

.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.inputFileBtn {
  position: relative !important;
  cursor: pointer !important;
}

.uploader .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  background-image: url('../../../images/icons/pngbackground.png');
  background-size: 100px 100px;
}

.dndArea {
  width: 560px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  margin: auto auto 24px auto;
  background: var(--pastel-blue);
  border: 2px dashed var(--light-blue);
}

.modalWrapper {
}

.dndArea.canDrop {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.iconWrapper {
  font-size: 32px;
  color: var(--game-blue);
}

.sidesWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.sidesWrapper + .footerWrapper {
  margin-top: 24px;
}

.imageWrapper {
  margin: auto;
  width: fit-content;
}

.imagePreview {
  max-height: 600px;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}

.cropWrapper {
  display: flex;
  height: fit-content;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  user-select: none;
}

.cropBox {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px dashed var(--snow);
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.4);
}

.cropPoint {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid var(--border-gray);
  margin-top: -5px;
  margin-left: -5px;
}

.backgroundEditorWrapper {
  width: 100%;
  display: flex;
}

.editorCanvas {
  background-image: url('../../../images/icons/pngbackground.png');
  background-size: 100px 100px;
}

.leftSide {
  display: flex;
  justify-content: center;
  max-width: 600px;
  width: 100%;
}

.rightSide {
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageSizesWrapper {
  position: relative;
  height: fit-content;
}

.chainIcon {
  font-size: 18px;
  color: var(--gray-font);
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.removableWrapper {
  position: relative;
}

.removableWrapper:hover .buttonsImageWrapper {
  display: flex;
}

.buttonsImageWrapper button {
  margin-bottom: var(--input-padding);
  width: 100%;
}
.buttonsImageWrapper button:last-child {
  margin-bottom: 0;
}

.buttonsImageWrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}

.btnsWrapper {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerBtn {
  margin-left: 5px;
}

.btnsWrapper button + button {
  margin-left: 5px;
}

.arrow {
  margin-right: 5px;
}

.btnArrow {
  height: 30px !important;
  width: 50% !important;
  border: none !important;
  font-size: 14px !important;
  box-shadow: none !important;
  background: none !important;
  color: var(--game-blue) !important;
}

.btnArrow span {
  border-bottom: 1px solid var(--game-blue) !important;
}

.applyBtn {
  max-height: 40px !important;
  height: 40px !important;
  width: 50% !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.canvasWrapper {
  margin: auto;
}

.modalConfirmText {
  margin-bottom: 8px;
}

.addyThink {
  background: url('../../../images/addythink.png') center top no-repeat;
  background-size: cover;
  width: 94px;
  height: 177px;
}

.busyWrapper {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.busyWrapper .hint {
  margin-top: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
}
