.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.label {
  color: var(--gray);
  margin-bottom: 5px;
}

.errors {
  display: flex;
  flex-direction: column;
}

.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-bottom: 5px;
}

.radioWrapper {
  display: flex;
  flex-direction: row;
}

.radio {
  height: var(--input-height);
  width: var(--input-height);
  background: var(--snow);
  border: 1px solid var(--border-gray);
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  cursor: pointer;
}

.radio.textRadio {
  width: auto;
  font-size: 12px;
}

.radio[disabled] {
  cursor: not-allowed;
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
}

.radio.active[disabled] {
  border: 1px solid var(--blue-line);
  background: var(--blue-line);
}

.radio:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.radio:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.radio.active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  color: var(--snow);
}
