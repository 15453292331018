.wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  color: var(--gray);
}

.errors {
  display: flex;
  flex-direction: column;
}

.error {
  color: #ff4d4f;
  font-size: 12px;
}

.white {
  padding: 5px;
}

.radioWrapper {
  display: flex;
  flex-direction: row;
}

.radio {
  border: none;
  height: 40px;
  width: 40px;
  background: var(--gray-input);
  color: var(--gray-500);
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  cursor: pointer;
}

.radio.tiny {
  height: 30px;
  width: 30px;
}

.radio.textRadio {
  width: auto;
  font-size: 12px;
}

.radio[disabled] {
  cursor: not-allowed;
  background: var(--gray-file-upload);
}

.radio.active[disabled] {
  background: var(--blue-line);
}

.radio:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.radio:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.radio.active {
  background: var(--game-blue);
  color: var(--snow);
}
