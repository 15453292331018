.input {
  white-space: nowrap;
}

:global .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--game-blue);
  border-color: var(--game-blue);
}

:global .ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--game-blue) !important;
}

:global .ant-checkbox-checked::after {
  border: 1px solid var(--game-blue) !important;
}

:global .ant-checkbox-inner {
  border-radius: 3px !important;
}

:global .ant-checkbox,
:global .ant-checkbox-wrapper {
  font-size: 14px !important;
}

@media all and (max-width: 1599px) {
  :global .ant-checkbox,
  :global .ant-checkbox-wrapper {
    font-size: 12px !important;
  }
}
