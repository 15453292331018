@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  --black: #272727;
  --night: #000000;
  --header: 40px;
  --white-header: 56px;
  --sub-header: 250px;
  --leads-message: 36px;
  --white-sidebar-full: 260px;
  --white-tiny-sidebar-full: 185px;
  --animation: 0.3s ease-in-out;
  --white-sidebar-minimize: 64px;
  --campaign-left-side: 512px;
  --preview-header: 48px;
  --small-screen: 1599px;
  --input-fsz: 14px;
  --rte-btn-height: 28px;
  --input-padding: 8px;
  --input-height: 26px;
  --section-margin: 16px;
  --cobalt: #272727;
  --light-gray-table: #838383;
  --br: 10px;
  --progress-bar: 46px;
  --gray: #999999;
  --gray-500: #686872;
  --gray-200: #e6e6e8;
  --gray-input: #f8f8f8;
  --gray-300: #cdcdd0;
  --gray-350: #f3f4f8;
  --gray-400: #9b9ba2;
  --gray-400-a: rgba(155, 155, 162, 0.5);
  --gray-600: #373644;
  --gray-title-font: #6e6e6e;
  --gray-file-upload: #f0f1f5;
  --gray-background: #f0f1f5;
  --gray-subtitle: #5c6574;
  --gray-font: #4f4f4f;
  --new-gray: #f5f6f8;
  --gray-input-preview: #d8def0;
  --gray-placeholder: #bfbfbf;
  --border-gray: #dfdfdf;
  --selected-row: #edeff8;
  --dark-gray: #949494;
  --light-gray-font: #c4c4c4;
  --light-gray-font-transparent: rgba(196, 196, 196, 0.3);
  --green: #a3e254;
  --green-light: #ddf8bc;
  --game-green: #67b900;
  --green-500: #00ca5d;
  --green-opacity: rgba(163, 226, 84, 0.2);
  --light-gray: #cacaca;
  --light-gray-border: #c4c4c4;
  --sidebar: 92px;
  --blue-font: #1754af;
  --button-box-shadow-color: rgba(152, 178, 255, 0.5);
  --box-shadow: 0 0 0 2px var(--button-box-shadow-color);
  --game-blue: #3261ec;
  --game-blue-200: #0045ff;
  --game-blue-opacity: rgba(50, 97, 236, 0.2);
  --dark-game-blue: #1754af;
  --blue-line: #98b2ff;
  --red: #dc3500;
  --red-400: #e62e2e;
  --red-500: #ff1e62;
  --danger-red: #ff3a29;
  --red-opacity: rgba(220, 53, 0, 0.1);
  --dark-red: #c73000;
  --light-red: #f7b9a5;
  --light-red-font: #f7b9a5;
  --game-light-blue: #4977ff;
  --gray-bg: #f1f3f6;
  --snow: #ffffff;
  --blue: #37507b;
  --dark-blue: #102447;
  --game-yellow: #fdbb24;
  --shadow-yellow: #ffaa1b;
  --purple: #eb2f96;
  --purple-opacity: rgba(235, 47, 150, 0.1);
  --game-yellow-transparent: rgba(253, 187, 36, 0.1);
  --yellow: #f9b51f;
  --light-yellow: #fff5c7;
  --dark-yellow: #dd9b06;
  --pastel-blue: #eff2ff;
  --light-blue: #ccdaff;

  /*    shadows*/

  --new-shadow: 0px 16px 22px rgba(0, 0, 0, 0.1), 0px 34px 55px rgba(0, 0, 0, 0.05), 0px 13px 66px rgba(0, 0, 0, 0.07);
  --filter-shadow: drop-shadow(0px 8px 16px rgba(67, 86, 100, 0.02)) drop-shadow(0px 16px 32px rgba(67, 86, 100, 0.16));
  --btn-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);

  --game-blue-hover: #7091f2;
  --game-blue-active: #1e4edc;

  --new-blue: #0a5dff;
  /*    new colors*/
  --new-dark-gray: #b2b2b2;
  --new-gray-3: #828b9a;
  --new-gray-4: #f6f6f6;
  --new-gray-5: #a69fad;
  --new-gray-6: #f8f8f8;
  --color-charcoal-1: #2e384d;
  --new-gray-bg: #f6f7fa;
  --new-gray-500: #686872;
  --new-gray-600: #373644;
  --new-gray-7: #eeeeef;
  --new-gray-8: #697794;
  --new-gray-9: #9597a1;
  --new-gray-10: #d4d9e0;
  --new-night: #111111;
  --new-light-gray: #ebebeb;
  --new-light-gray-2: #e2e2ea;
  --new-light-gray-3: #fbfbfc;
  --new-yellow: #ffaa1b;
  --new-yellow-hover: #ffc460;
  --new-yellow-active: #f89b00;
  --new-green-500: #71c516;
  --new-green-400: #34b53a;
  --new-green-light: #e2fbd7;
  --new-peach: #fffaeb;
  --new-peach-2: #f8d4a2;
  --new-peach-3: #f6c9b6;
  --new-orange-500: #ff8500;
  --new-orange-danger: #dc6803;
  --new-orange-dangerous: #ab4f01;
  --new-purple: #8a24ff;
  --new-light-purple: #f9f5ff;
  --new-light-purple-2: #d8d1ff;
  --new-light-blue: #ebf0fe;

  --new-red: #ff1e62;
  --new-cherry: #ea2e96;
  --new-cherry-light: #ffebf4;
  --new-red-light: #fff1f3;
  --new-red-light-2: #fad5d5;

  --campaigns-list-sidebar-width: 240px;
  --campaigns-list-sidebar-width-small: 240px;
}

@media all and (max-width: 1599px) {
  body {
    --sidebar: 70px;
    --header: 30px;
    --leads-message: 26px;
    --input-height: 20px;
    --input-fsz: 12px;
  }
}

body.scroll-disabled {
  overflow-y: hidden;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: none !important;
  border-radius: 0 0 10px 10px !important;
}

.ant-modal-header {
  border-bottom: none !important;
  border-radius: 10px 10px 0 0 !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-popover-title {
  border: none !important;
}

.ant-popover-inner {
  border-radius: 10px !important;
}

.ant-modal-footer:empty {
  padding: 0 !important;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
}

.ant-popover-buttons .ant-btn {
  border-radius: 15px !important;
  height: var(--input-height) !important;
  padding: 5px 12px !important;
  font-size: var(--input-fsz) !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ant-popover-buttons .ant-btn {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--gray-file-upload);
}

.ant-popover-buttons .ant-btn:hover {
  color: var(--dark-game-blue);
  background: var(--gray-input-preview);
  border: 1px solid var(--gray-input-preview);
}

.ant-popover-buttons .ant-btn:focus,
.ant-popover-buttons .ant-btn:active {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
}

.ant-popover-buttons .ant-btn[disabled] {
  background: var(--snow);
  color: var(--gray-input-preview);
  border: 1px solid var(--gray-file-upload);
}

.ant-popover-buttons .ant-btn.ant-btn-primary {
  background: var(--game-blue);
  color: var(--snow);
  border: 1px solid var(--game-blue);
}

.ant-popover-buttons .ant-btn.ant-btn-primary:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.ant-popover-buttons .ant-btn.ant-btn-primary:focus,
.ant-popover-buttons .ant-btn.ant-btn-primary:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.ant-popover-buttons .ant-btn.ant-btn-primary[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
}

.ant-popover-message-title {
  font-size: var(--input-fsz) !important;
  color: var(--cobalt);
}

.ant-popover-message-title {
  font-size: var(--input-fsz) !important;
  color: var(--cobalt);
}

.ant-modal-confirm-btns .ant-btn {
  border-radius: 15px !important;
  height: var(--input-height) !important;
  padding: 5px 12px !important;
  font-size: var(--input-fsz) !important;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ant-modal-confirm-btns .ant-btn {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--gray-file-upload);
}

.ant-modal-confirm-btns .ant-btn:hover {
  color: var(--dark-game-blue);
  background: var(--gray-input-preview);
  border: 1px solid var(--gray-input-preview);
}

.ant-modal-confirm-btns .ant-btn:focus,
.ant-modal-confirm-btns .ant-btn:active {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
}

.ant-modal-confirm-btns .ant-btn[disabled] {
  background: var(--snow);
  color: var(--gray-input-preview);
  border: 1px solid var(--gray-file-upload);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background: var(--game-blue);
  color: var(--snow);
  border: 1px solid var(--game-blue);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary:focus,
.ant-modal-confirm-btns .ant-btn.ant-btn-primary:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.ant-modal-confirm-btns .ant-btn.ant-btn-primary[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
}

.ant-modal-confirm-title {
  font-size: var(--input-fsz) !important;
  color: var(--cobalt) !important;
}

.ant-modal-confirm-btns {
  display: flex;
}

.ant-modal-confirm .ant-modal-body {
  padding: 24px !important;
}

.ant-modal-confirm-body {
  display: flex;
  align-items: center;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Content--after-open {
  animation: scale-up-center 0.2s;
}

.ReactModal__Content--before-close {
  animation: scale-down-center 0.2s;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

#tidio-chat-iframe {
  width: 110px;
}
