.white {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: var(--white-header);
  background: var(--snow);
  border-bottom: 1px solid #ebebeb;
  font-family: 'Inter', sans-serif;
}

.white.withSidebar.withSidebar-full {
  transition:
    left var(--animation),
    width var(--animation);
  width: calc(100% - var(--white-sidebar-full));
  left: var(--white-sidebar-full);
}

.white.withSidebar.withSidebar-full.tinySidebar {
  width: calc(100% - var(--white-tiny-sidebar-full));
  left: var(--white-tiny-sidebar-full);
}

.white.withSidebar.withSidebar-minimize {
  width: calc(100% - var(--white-sidebar-minimize));
  transition:
    left var(--animation),
    width var(--animation);
  left: var(--white-sidebar-minimize);
}

.white.withSidebar.withSidebar-minimize.tinySidebar {
  width: calc(100% - var(--white-sidebar-minimize));
  transition:
    left var(--animation),
    width var(--animation);
  left: var(--white-sidebar-minimize);
}

.container {
  margin: 0 auto;
  align-items: center;
  height: 100%;
  display: flex;
  width: 100%;
  padding: 0 20px;
  justify-content: space-between;
}

.logoWrapper {
  height: 100%;
  padding: 10px 0;
  margin-right: 16px;
}

.whiteLogoWrapper {
  height: 22px;
  margin-right: 16px;
}

.logoWrapper a {
  display: flex;
  height: 100%;
}

.logoWrapper img {
  height: 100%;
}

.menus {
  flex: 3;
}

.wrapper.white .menus {
  height: 100%;
}

.list {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0;
}

.link {
  color: var(--snow);
  background: #ffffff !important;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
}

.white .link.activeLink {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.link:hover {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

.white .link:hover {
  background: var(--game-blue);
  color: var(--snow);
}

.white .link {
  color: var(--night);
  font-size: 15px;
  border-radius: 60px;
  margin: 9px 6px;
  padding: 7px 12px;
  transition:
    color var(--animation),
    background var(--animation);
}

.whiteIcon {
  margin-right: 13px;
}

.icon {
  margin-right: 8px;
}

.logoutLi .icon {
  margin: 0;
  padding: 0 15px;
}

.rightSide .list .link.onlyIcon .icon {
  padding: 0;
}

.rightSide .list li {
  margin-right: 0;
}

.logoutWrapper {
  margin: 0;
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
  outline: none;
}

.logoutWrapper:hover {
  color: var(--snow);
}

.dropdownTrigger {
  color: var(--snow);
}

.whiteDropdownWrapper {
  padding: 6px 0;
}

.dropdown {
  min-width: 130px;
  max-width: 130px;
  justify-content: flex-end;
}

.dropdown > div:first-child {
  max-width: 130px;
  flex-direction: row-reverse;
  width: auto;
  padding: 10px 6px;
}

.dropdown p {
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 35px;
}

.dropdown > div > span {
  margin: 0 0 0 4px;
}

.userDropdown {
  justify-content: flex-end;
}

.brands {
  background: var(--gray-input);
}

.label {
  color: inherit;
}

.popoverOverlay :global .ant-popover-arrow {
  display: none;
}

.popoverOverlay :global .ant-popover-inner {
  border-radius: 5px;
}
.popoverOverlay :global .ant-popover-inner-content {
  position: relative;
  padding: 6px !important;
  display: flex;
  min-width: 180px;
  border-radius: 5px;
  border: 1px solid var(--new-light-gray-2);
  flex-direction: column;
  align-items: center;
  background: var(--snow);
}

.popoverWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.popoverOverlay div {
  padding: 0 !important;
}
.popoverBtn {
  background: var(--snow);
  border: none;
  color: var(--night);
  height: 32px;
  width: 180px;
  white-space: nowrap;
  padding: 4px 10px;
  display: flex;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.24px;
  outline: none;
  cursor: pointer;
}

.popoverBtn:hover {
  background: var(--game-blue);
  color: var(--snow);
}
.popoverBtn[disabled] {
  color: var(--game-blue-opacity);
  cursor: not-allowed;
}
.popoverBtn[disabled]:hover {
  color: var(--game-blue-opacity);
  background: var(--snow);
}

.unlockButton {
  margin-right: 8px;
}

.book img {
  margin-bottom: 3px;
}

@media all and (max-width: 1599px) {
  .label {
    font-size: 12px;
  }

  .dropdownTrigger p {
    font-size: 12px;
  }

  .link {
    padding: 4px 12px;
  }
}
