.wrapper {
  color: var(--game-blue);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex-direction: column;
}

.text {
  align-items: center;
  margin-top: 8px;
  z-index: 1;
}

.white {
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  -moz-animation: spin 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  animation: spin 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  align-items: center;
  justify-content: center;
}

.white img {
  width: 36px;
  height: 36px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
