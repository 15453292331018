.dndArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  min-height: 150px;
  border-radius: 10px;
  background: var(--pastel-blue);
  border: 2px dashed var(--light-blue);
  position: relative;
  margin: 10px 30px 0;
}

.spinnerWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: rgba(255, 255, 255, 0.7);
}

.uploaderArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-height: 150px;
  position: relative;
}

.filesPreview {
  width: 100%;
  border-bottom: 2px dashed var(--light-blue);
}

.dndArea.small {
  height: auto;
  padding: 5px;
  justify-content: unset;
  align-items: unset;
  flex-direction: unset;
  min-height: unset;
}

.dndArea p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.removeWrapper {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 100%;
  background: var(--red-400);
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.removeWrapper * {
  height: 12px;
  width: 12px;
  justify-content: center;
  align-items: center;
}

.iconsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.iconsWrapper .icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--snow);
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  position: relative;
}

.iconsWrapper .icon img {
  max-width: 100%;
  max-height: 100%;
}

.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.buttonsWrapper {
  display: flex;
}

.inputFileBtn {
  position: relative !important;
  cursor: pointer !important;
}

.inputFileBtn + .inputFileBtn {
  margin-left: 4px;
}

.dndArea.canDrop {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.row {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
}

.imageWrapper {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWrapper {
  width: 40px;
  height: 40px;
  margin-bottom: -5px;
}

.iconWrapper .cloud {
  width: 40px;
  height: 40px;
}

.image {
  max-width: 70px;
  max-height: 70px;
}

.remove {
  width: 20px;
  height: 20px;
}
