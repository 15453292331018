.slider {
  width: 100%;
}

.slider :global .ant-slider-track,
.slider:hover :global .ant-slider-track {
  background: var(--game-blue) !important;
}
.slider :global .ant-slider-handle,
.slider:hover :global .ant-slider-handle {
  border: 2px solid var(--game-blue) !important;
}

.sliderWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: var(--input-height);
}

.sliderValue {
  width: 35px;
  min-width: 35px;
  margin-left: 8px;
  font-size: var(--input-fsz);
  color: var(--black);
}

.sliderValue.disabled {
  color: var(--light-gray-font);
}

.slider.disabled :global .ant-slider-track,
.slider.disabled:hover :global .ant-slider-track {
  background: var(--blue-line) !important;
}
.slider.disabled :global .ant-slider-handle,
.slider.disabled:hover :global .ant-slider-handle {
  border: 2px solid var(--blue-line) !important;
}
