.select {
  position: relative;
}

.wrapper {
  width: 150px;
}

.wrapper.inlineWrapper {
  width: 100%;
}
.wrapper.inline .toggler {
  flex: 1 100%;
}

.errors {
  color: var(--new-red);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
}

.listWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  width: 100%;
  z-index: 2;
  filter: var(--filter-shadow);
  background: var(--snow);
  border: 1px solid var(--new-light-gray-2);
  border-radius: 5px;
}

.select.active .listWrapper {
  display: flex;
}

.select.active .arrow {
  transform: rotateZ(-180deg);
}

.listWrapper ul {
  padding: 15px 6px;
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  margin-bottom: 0;
}

.listWrapper ul li {
  padding: 3px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.24px;
  color: var(--night);
  margin-bottom: 6px;
  font-family: 'Inter', sans-serif;
}

.listWrapper ul li label {
  cursor: pointer;
}

.listWrapper ul li:hover {
  background: var(--pastel-blue);
}

.toggler {
  border: 1px solid var(--new-gray-6);
  background: var(--new-gray-6);
  box-shadow: none;
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 32px 7px 16px;
  align-items: center;
  display: flex;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.toggler.tiny {
  height: 30px;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 10px;
  height: 7px;
  margin-top: -3.5px;
  pointer-events: none;
  transition: transform 0.1s ease-in-out;
}

.placeholder {
  color: var(--new-gray-3);
}

.select.active .toggler {
  border: 1px solid var(--new-blue);
}

.toggler.hasError,
.select.active .toggler.hasError {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.toggler.disabled {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.toggler.disabled .placeholder {
  color: var(--new-gray-5);
}
