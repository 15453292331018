.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--input-padding);
}

.label {
  color: var(--gray);
  margin-bottom: 5px;
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  margin: 0;
  color: var(--black);
}
