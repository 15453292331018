.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--input-padding);
  min-width: 110px;
}

.inputWrapper {
  width: 110px;
  display: flex;
  position: relative;
}

.valueInput {
  box-shadow: none;
  border-radius: 8px;
  width: 100%;
  padding: 2px 10px 2px 26px;
  align-items: center;
  position: relative;
  background: var(--snow);
  color: var(--cobalt);
  font-size: var(--input-fsz);
  height: var(--input-height);
  outline: none;
  border: 1px solid var(--gray-input-preview);
}

.valueInput[disabled],
.valueInput[disabled]:hover {
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
  color: var(--light-gray-font);
}

.valueInput:active {
  border: 1px solid var(--cobalt);
}

.valueInput:hover {
  border: 1px solid var(--game-blue);
}

.valueInput:focus {
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
  background: var(--snow);
  color: var(--cobalt);
}

.inputWrapper .colorInput {
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  margin-right: 10px;
  opacity: 0;
  position: absolute;
}

.inputWrapper .colorInput[disabled] {
  opacity: 0 !important;
}

.color {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  border: 1px solid var(--border-gray);
}

.color.transparent:after {
  width: 1px;
  height: 100%;
  background: var(--red);
  top: 0;
  display: inline-block;
  left: 50%;
  content: '';
  transform: rotateZ(45deg) translateX(-50%);
  position: absolute;
}

.clearBtn {
  border: none;
  background: none;
  position: absolute;
  right: 3px;
  top: 50%;
  font-size: 9px;
  transform: translateY(-50%);
  line-height: 9px;
  cursor: pointer;
  padding: 5px;
  color: var(--black);
  outline: none;
}
