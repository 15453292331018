.mediaList {
  display: flex;
  flex-wrap: wrap;
  height: 136px;
}

.oneRow {
  height: 68px;
}

.mediaList .item {
  padding-right: 4px;
  padding-bottom: 4px;
}

.pagesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  margin: 10px auto;
}

.pagesWrapper * {
  user-select: none;
}

.pageButton {
  cursor: pointer;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 0 2px;
  border: 1px solid var(--new-light-gray);
}

.pageButton p {
  font-family: Inter, 'serif';
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: -0.5px !important;
  text-align: center !important;
}
.pageButton span {
  width: 18px !important;
}

.pageButton.active {
  background: var(--new-light-gray);
  color: var(--game-blue) !important;
}

.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.inputFileBtn {
  width: 150px !important;
  height: 28px !important;
}
.inputFileBtn * {
  cursor: pointer !important;
}

.inputFileBtn input {
  color: transparent;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.inputFileBtn .imageWrapper {
  background: #f0f2ff;
}

.imageWrapper {
  border: 2px solid var(--snow);
  overflow: hidden;
  width: 64px;
  height: 64px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
}

.checkmark {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
}

.image {
  user-select: none;
  width: 100%;
  height: 100%;
  background-image: url('../../../../images/icons/pngbackground.png');
  background-size: 100px 100px;
}

.uploaderButtonBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.imageWrapper.active {
  border: 2px solid var(--game-blue);
}

.sidesWrapper {
  display: flex;
}

.leftSide {
  width: 100%;
}

.dndArea {
  background: var(--pastel-blue);
  width: 540px;
  height: 172px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 10px;
  margin-top: 10px;
  border: 2px dashed var(--light-blue);
}

.dndArea p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: var(--game-blue);
  letter-spacing: -0.3px !important;
}

.dndArea.canDrop {
  background: var(--green-opacity);
}

.rightSide {
  width: 476px;
  min-width: 476px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.previewList {
  position: relative;
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.previewImage {
  max-width: 100%;
  max-height: 100%;
}

.previewImageWrapper.multipreview .previewImage {
  max-height: 80px;
}

.previewImageWrapper.multipreview {
  margin: 4px;
  min-width: 100px;
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.previewImageWrapper .previewImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.previewImageWrapper.multipreview .previewImageContainer {
  height: 80px;
  width: 80px;
  margin-bottom: 4px;
}

.recentsTitle {
  margin-top: 32px;
  padding-bottom: 12px;
  font-family: Inter, 'serif' !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 17.6px !important;
  text-align: left !important;
}

.sectionTitle {
  padding-bottom: 8px;
  font-family: Inter, serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 17.6px !important;
  text-align: left !important;
}

.filtersWrapper {
  display: flex;
  margin-top: 15px;
}

.topCategory {
  width: 258px;
}

.subCategory {
  width: 258px;
  margin-left: 24px;
}

.topCategory label,
.subCategory label {
  margin-right: 12px !important;
  font-family: Inter, 'serif' !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.94px !important;
  letter-spacing: -0.02em !important;
  text-align: left !important;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.spinnerWrapper.deleteSpinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.spinnerWrapper.deleteSpinner .spinner div {
  display: none;
}

.inputFileBtn {
  position: relative !important;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.iconWrapper img {
  width: 100%;
  height: 100%;
}

.uploadHint {
  font-size: 14px !important;
  font-family: Inter, 'serif' !important;
  font-weight: 400 !important;
  letter-spacing: -0.30000001192092896px !important;
  text-align: center !important;
}
.uploadHint + .uploadHint {
  margin-top: -10px;
  margin-bottom: 5px;
}

.iconWrapper svg {
  width: 80%;
  height: 80%;
}

.footerWrapper {
  height: 40px;
  min-height: 40px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footerIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: var(--new-gray-4);
  cursor: not-allowed;
}

.footerIcon * {
  width: 20px;
}

.footerIcon.active {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid var(--new-gray-5);
}

.footerBtn {
  cursor: pointer;
  height: 40px !important;
  min-height: 40px;
  width: 140px !important;
}

.footerBtn.inActive {
  background: var(--new-gray-4) !important;
  border: 1px solid #d0d5dd !important;
  cursor: not-allowed;
}

.footerIcon + .footerIcon,
.footerIcon + .footerBtn {
  margin-left: 8px;
}

.emptyPreview {
  width: 100%;
  height: 100%;
  background: #f2f4f7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.emptyPreview p {
  font-family: Inter, 'serif';
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #475467;
}
