.wrapper {
  display: flex;
  width: 100%;
  background: var(--snow);
  box-shadow:
    0 4px 8px rgba(67, 86, 100, 0.102956),
    0 8px 16px rgba(67, 86, 100, 0.0780977);
  border-radius: 12px;
  min-height: 78px;
  padding: 14px 64px 14px 180px;
  animation: scale-up-center 0.2s;
  transform-origin: top center;
  pointer-events: all;
  position: relative;
  margin-bottom: 20px;
}

.messageModal {
  position: absolute;
  border-radius: 12px;
  background: var(--snow);
  box-shadow:
    0 4px 8px rgba(67, 86, 100, 0.102956),
    0 8px 16px rgba(67, 86, 100, 0.0780977);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 400px;
  width: 800px;
  animation: back-in-down 1s;
  display: flex;
  overflow: hidden;
  pointer-events: all;
  transform-origin: top center;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #100c31;
  transition: opacity 1s;
  opacity: 0;
}

.overlay.show {
  opacity: 0.6;
}

.imageWrapper {
  width: 400px;
  min-width: 400px;
  max-height: 400px;
  height: auto;
}

.messageImage {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}

.messageText {
  padding: 10px 10px 10px 20px;
  width: 100%;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.buttonWrapper * {
  text-decoration: none !important;
}

.container {
  display: flex;
  position: fixed;
  top: calc(var(--white-header) + 12px);
  flex-direction: column;
  z-index: 12;
  pointer-events: none;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 32px;
}

.wrapper.closing {
  animation: scale-down-center 0.25s;
  transform-origin: top center;
}

.messageModal.closing {
  animation: back-in-up 1s forwards;
}

.wrapper.red {
  background: linear-gradient(87.71deg, #e62e2e 0%, #ffffff 15.84%), #ffffff;
}

.wrapper.yellow {
  background: linear-gradient(87.71deg, #ffaa1b 0%, #ffffff 15.84%), #ffffff;
}

.wrapper.blue {
  background: linear-gradient(87.71deg, var(--game-blue) 0%, #ffffff 15.84%), #ffffff;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.textWrapper.hasButton {
  height: calc(100% - 45px);
}

.textWrapper.popup * {
  max-width: 340px;
}

.close {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: #f3f4f8;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.closePopup {
  top: 10px;
  right: 10px;
  transform: unset;
}

.addy {
  position: absolute;
  bottom: 0;
  left: 0;
}

.text {
  color: var(--black);
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  /* identical to box height, or 156% */

  letter-spacing: -0.2px !important;
}

.grayText {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 25px !important;
  /* identical to box height, or 156% */

  letter-spacing: -0.2px !important;

  color: #808192 !important;
  display: flex;
}

.brandSlugPopup {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}

.link {
  color: var(--game-blue) !important;
  cursor: pointer;
  text-decoration: underline;
  margin: 0 4px;
}

@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes back-in-down {
  0% {
    transform: translate(-50%, -300%) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes back-in-up {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  80% {
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: translate(-50%, -300%) scale(0.7);
    opacity: 0.7;
  }
}
