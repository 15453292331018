.wrapper {
  position: relative;
  width: 100%;
}

.withIcon {
  padding-left: 25px !important;
}

.icon {
  font-size: var(--input-fsz);
  color: var(--cobalt);
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 7px;
  transform: translateY(-50%);
}

.newForm {
  height: 50px !important;
  border-radius: 5px !important;
  outline: none !important;
  padding: 12px 16px !important;
  border: none !important;
  background: #f8f8f8 !important;
  font-size: 16px !important;
  font-weight: 400;
  color: #828b9a !important;
}

.newForm.withIcon {
  padding-left: 36px !important;
}

.icon.newIcon {
  font-size: 16px !important;
  color: var(--gray-placeholder) !important;
  top: 0;
  z-index: 1;
  left: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: none;
}

.phoneInputWrapper {
  display: flex;
}
.phoneWrapper {
  margin-left: 8px;
  flex: 1 100%;
}

.select {
  min-width: 160px;
}
