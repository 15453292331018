.item {
  padding-right: 4px;
  padding-bottom: 4px;
}

.inputFileBtn .imageWrapper {
  background: #f0f2ff;
}

.imageWrapper {
  border: 2px solid var(--snow);
  overflow: hidden;
  width: 64px;
  height: 64px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
}

.imageWrapper.active {
  border: 2px solid var(--game-blue);
}

.image {
  user-select: none;
  width: 100%;
  height: 100%;
  background-image: url('../../../../../images/icons/pngbackground.png');
  background-size: 100px 100px;
}

.checkmark {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.spinnerWrapper.deleteSpinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.spinnerWrapper.deleteSpinner .spinner div {
  display: none;
}
