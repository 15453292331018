:global .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

:global .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner:hover {
  color: var(--cobalt) !important;
}

:global .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--gray-input-preview) !important;
  border-radius: 6px !important;
}

:global .ant-picker-cell-in-view .ant-picker-cell-inner {
  color: var(--cobalt) !important;
  border-radius: 6px !important;
  border: 1px solid transparent !important;
}

:global .ant-picker-cell-in-view .ant-picker-cell-inner:hover {
  background: none !important;
  border: 1px solid var(--game-blue) !important;
}

:global .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
:global
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(
    .ant-picker-cell-range-end
  ):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: none !important;
}

:global .ant-picker-dropdown .ant-picker-footer .ant-btn-primary {
  border-radius: 8px !important;
  background: var(--game-blue) !important;
  border: 1px solid var(--game-blue) !important;
  height: 16px !important;
}

:global .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: var(--game-blue) !important;
  color: var(--snow) !important;
}

:global .ant-picker-dropdown .ant-picker-footer .ant-btn-primary[disabled] {
  background: var(--blue-line) !important;
  border: 1px solid var(--blue-line) !important;
  color: var(--snow) !important;
}

:global .ant-picker-dropdown .ant-picker-footer .ant-picker-now-btn {
  color: var(--game-blue) !important;
}
