.typo {
  font-family: 'Roboto', sans-serif;
  color: var(--black);
  margin: 0;
  font-weight: 400;
}

.typo.white {
  font-family: 'Inter', sans-serif;
}

.typo.inter {
  font-family: 'Inter', sans-serif;
}

.gray {
  color: var(--gray);
}

.h1 {
  font-size: 28px;
  line-height: 32px;
}

.h2 {
  font-size: 22px;
  line-height: 25px;
}

.h3 {
  font-size: 18px;
  line-height: 21px;
}

.h4 {
  font-size: 16px;
  line-height: 18px;
}

.h5 {
  font-size: 14px;
  line-height: 16px;
}

.h6 {
  font-size: 14px;
  line-height: 16px;
}

.p {
  font-size: 12px;
  line-height: 16px;
  font-family: Inter, serif;
}

.small {
  font-size: 12px;
  line-height: 14px;
}

.bold {
  font-weight: 700;
}

.semi {
  font-weight: 500;
}

@media all and (max-width: 1599px) {
  .h1 {
    font-size: 26px;
    line-height: 30px;
  }

  .h2 {
    font-size: 20px;
    line-height: 23px;
  }

  .h3 {
    font-size: 16px;
    line-height: 19px;
  }

  .h4 {
    font-size: 14px;
    line-height: 16px;
  }

  .h5 {
    font-size: 12px;
    line-height: 14px;
  }

  .h6 {
    font-size: 12px;
    line-height: 14px;
  }

  .p {
    font-size: 12px;
    line-height: 16px;
  }

  .small {
    font-size: 10px;
    line-height: 12px;
  }
}

.h1.white {
  font-size: 36px;
  line-height: 56px;
  font-weight: 700;
  letter-spacing: -2.5px;
}

.h2.white {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -2px;
}

.h3.white {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -2px;
}

.h4.white {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.5px;
}

.h5.white {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
}

.h6.white {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.5px;
}

.p.white {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 16px;
}

.white.bold {
  font-weight: 600 !important;
}
