.modal {
  min-width: 800px;
}

.coin {
  position: absolute;
  top: 115px;
  right: 60px;
  height: 150px;
  width: 150px;
}

.wrapper {
  width: 500px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  text-align: left;
}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.5px;
  text-align: left;
  color: var(--gray-500);
  margin-top: 40px;
}

.buttonsWrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.buttonsWrapper .btn + .btn {
  margin-left: 8px;
}
