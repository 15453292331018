.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.inputFileBtn {
  position: relative !important;
  cursor: pointer !important;
}

.dndArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  background: var(--gray-file-upload);
  border: 1px dashed var(--gray-font);
}

.modalWrapper {
}

.dndArea.canDrop {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.iconWrapper {
  font-size: 48px;
  color: var(--green);
}

.iconFileWrapper {
  font-size: 48px;
  color: var(--game-blue);
}

.fileName {
  font-size: var(--input-fsz);
  text-align: center;
  margin-bottom: var(--input-padding);
  color: var(--cobalt);
}

.withFileWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.uploaderButton {
  margin-bottom: var(--input-padding);
  height: var(--input-height);
  max-width: 180px;
}

.valueText {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
