.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 12px;
}

.wrapper.inline {
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
}

.wrapper.withError {
  /*animation: shake-horizontal 0.8s linear both;*/
}

.wrapper.withError.inline {
  display: flex;
  flex-direction: column;
}

.inlineBody {
  display: flex;
  align-items: center;
}

.inlineBodyWrapper {
  display: flex;
  flex: 1 100%;
  width: 100%;
  flex-direction: column;
}

.fieldWrapper {
  flex: 1 100%;
  display: flex;
  flex-direction: column;
}

@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(3px);
  }
  90% {
    transform: translateX(-3px);
  }
}

.errorsLabelWrapper {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.label {
  font-size: 14px;
  color: var(--night);
  font-family: 'Inter', sans-serif;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 16px;
}

.label.alignLabelRight {
  text-align: right;
}

.wrapper.inline .errorsLabelWrapper {
  margin-bottom: 0;
}

.wrapper.inline .label {
  margin-right: 6px;
  font-weight: 400;
}
.errors {
  color: var(--new-red);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
}

.typo {
  height: 40px;
  display: flex;
  align-items: center;
}
