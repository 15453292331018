.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.wrapper.disabled {
  cursor: not-allowed;
}

.checkbox {
  display: none;
}

.togglerWrapper {
  position: relative;
}

.spotWrapper {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  transition: all 0.3s;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #d9d9d9;
}

.togglerWrapper.active .spotWrapper {
  background-color: var(--game-blue);
  border-color: var(--game-blue);
}

.togglerWrapper.active .spotWrapper:after {
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.spotWrapper:after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition:
    all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  content: ' ';
}

.togglerWrapper.active:after {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}

.errorsLabelWrapper {
  margin-left: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.label {
  font-size: 14px;
  color: var(--night);
  font-family: 'Inter', sans-serif;
  font-weight: 400 !important;
  line-height: 22px !important;
  /* identical to box height, or 157% */
  letter-spacing: -0.2px !important;
}

.errors {
  color: var(--new-red);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: column;
}

.typo {
  height: 40px;
  display: flex;
  align-items: center;
}
