.wrapper {
  width: 100%;
  border: none;
  color: var(--cobalt);
}

.grayRow {
  background: var(--new-light-gray);
}

.wrapper td {
  padding: 4px 10px;
}

.wrapper tr {
  border-bottom: 1px solid var(--gray-file-upload);
}

.wrapper tbody {
  max-height: 300px;
  overflow-y: auto;
}

.wrapper.clear tr {
  border-bottom: none;
}

.wrapper.clearWithHeader tbody tr {
  border-bottom: none;
}

.wrapper tbody tr {
  cursor: pointer;
}

.wrapper tbody tr.active {
  background: var(--selected-row);
}

.wrapper thead tr td {
  color: var(--light-gray-table);
  white-space: nowrap;
  font-size: 12px;
}

.sortableWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.sortableWrapper.white .sortableLabel {
  font-size: 9px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: var(--night);
  letter-spacing: 1.5px;
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 16px;
  opacity: 1;
}

.sortableLabel,
.sortableIcon {
  color: var(--cobalt);
  white-space: nowrap;
  font-size: 12px;
  opacity: 0.6;
}

.sortableWrapper.disabled {
  cursor: not-allowed;
}

.sortableIcon {
  margin-left: 4px;
  color: var(--light-gray-font);
}

.sortableIcon.active,
.sortableLabel.active {
  color: var(--cobalt);
  opacity: 1;
  background: none;
}
