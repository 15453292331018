.field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightSide {
  width: fit-content;
}

.pagesWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
}

.pagesWrapper * {
  user-select: none;
}

.white .pageButton {
  background: #ffffff;
}

.whiteSelect {
  height: 32px !important;
  background: #ffffff;
}

.pageButton {
  cursor: pointer;
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 0 2px;
  border: 1px solid var(--new-light-gray);
}

.pageButton p {
  font-family: Inter, 'serif';
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: -0.5px !important;
  text-align: center !important;
}
.pageButton span {
  width: 18px !important;
}

.pageButton.active {
  background: var(--new-light-gray);
  color: var(--game-blue) !important;
}
