.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--input-padding);
  min-width: 110px;
  min-height: 40px;
}

.inputWrapper {
  width: 110px;
  display: flex;
  position: relative;
}

.valueInput {
  box-shadow: none;
  border-radius: 5px;
  width: 100%;
  padding: 2px 18px 2px 26px;
  align-items: center;
  position: relative;
  background: var(--gray-input);
  color: var(--gray-600);
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  height: 40px;
  transition: border var(--animation);
  outline: none;
  border: 1px solid var(--gray-input);
}

.valueInput.tiny {
  height: 30px;
}

.valueInput:focus {
  border: 1px solid var(--game-blue);
}

.valueInput:active {
  border: 1px solid var(--game-blue);
}

.inputWrapper .colorInput {
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  margin-right: 10px;
  opacity: 0;
  position: absolute;
}

.valueInput.danger {
  border: 1px solid #ff0000;
}

.inputWrapper .colorInput[disabled] {
  opacity: 0 !important;
}

.inputWrapper.transparent .valueInput {
  background: var(--snow);
  color: var(--new-gray-5);
}

.inputWrapper.disabled .valueInput {
  cursor: not-allowed;
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
}

.color {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  border: 1px solid var(--border-gray);
}

.color.transparent:after {
  width: 1px;
  height: 100%;
  background: var(--red);
  top: 0;
  display: inline-block;
  left: 50%;
  content: '';
  transform: rotateZ(45deg) translateX(-50%);
  position: absolute;
}

.clearBtn {
  border: none;
  background: none;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 3px;
  outline: none;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clearIcon {
  width: 12px;
}

.brandColorWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: 2px;
  cursor: pointer;
  transition: all 500ms;
  border-radius: 2px;
  position: relative;
  width: 16px;
  height: 16px;
}

.brandColorWrapper .pin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  user-select: none;
  position: absolute;
  clip-path: polygon(50% 50%, 100% 0, 100% 100%);
  border: 1px solid var(--border-gray);
}

.pin1 {
  transform: rotate(-45deg);
}

.pin2 {
  transform: rotate(135deg);
}

.pin3 {
  transform: rotate(225deg);
}

.pin4 {
  transform: rotate(45deg);
}

.popoverTitle {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

.popoverColor {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 6px 8px;
  border: 1px solid var(--gray-input);
  margin-top: 4px;
  cursor: pointer;
  border-radius: 8px;
  background: #ffffff;
  width: 100% !important;
}

.active {
  background: var(--light-blue);
}

.popoverWrapper {
  width: 100px;
}

.colorPin {
  border: 1px solid var(--light-gray);
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}
