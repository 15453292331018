:global .ant-btn-loading::before {
  position: relative !important;
}

.button {
  border-radius: 15px !important;
  height: var(--input-height) !important;
  padding: 5px 12px !important;
  font-size: var(--input-fsz) !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}

.button.link {
  border-radius: 0 !important;
  color: var(--game-blue);
}

.button.link:hover,
.button.link:active,
.button.link:focus {
  color: var(--dark-game-blue);
}

.button.primary {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
}

.button.primary:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.button.primary:focus,
.button.primary:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.button.primary[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
}

.button.danger {
  background: var(--red);
  border: 1px solid var(--red);
}

:global .ant-btn-sm {
  padding: 0 7px !important;
  height: 15px !important;
  font-size: 10px !important;
}

.button.danger:hover {
  background: var(--dark-red);
  border: 1px solid var(--dark-red);
}

.button.danger:focus,
.button.danger:active {
  background: var(--red);
  border: 1px solid var(--red);
  box-shadow: var(--box-shadow);
}

.button.danger[disabled] {
  background: var(--light-red);
  color: var(--light-red-font);
  border: 1px solid var(--light-red);
}

.button.ghost {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--gray-file-upload);
}

.button.ghost:hover {
  color: var(--dark-game-blue);
  background: var(--gray-input-preview);
  border: 1px solid var(--gray-input-preview);
}

.button.ghost:focus,
.button.ghost:active {
  background: var(--snow);
  color: var(--dark-game-blue);
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
}

.button.ghost[disabled] {
  background: var(--snow);
  color: var(--gray-input-preview);
  border: 1px solid var(--gray-file-upload);
}

.button.ghost.newForm,
.button.primary.newForm {
  margin-top: 20px;
  width: 100%;
  padding: 12px 16px !important;
  height: 50px !important;
  border-radius: 4px !important;
  font-size: 18px !important;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  box-shadow: 0 20px 30px -10px #ffaa1b !important;
}

.button.newForm.withoutShadow {
  box-shadow: none !important;
}

.button.newForm.small {
  font-size: 14px !important;
  max-width: 220px;
  padding: 9px 21px !important;
  height: auto !important;
  box-shadow: 0 10px 15px -5px #ffaa1b !important;
}

.button.newForm.small.withoutShadow {
  box-shadow: none !important;
}

.button.ghost.newForm {
  border: 2px solid var(--game-yellow) !important;
  color: var(--game-yellow) !important;
  background: var(--snow) !important;
}

.button.primary.newForm {
  border: none !important;
  background: var(--game-yellow) !important;
  color: var(--snow) !important;
}

.button.primary.newForm[disabled] {
  border: none !important;
  background: var(--dark-yellow) !important;
  color: var(--snow) !important;
}

.button.link.newForm {
  padding: 12px 16px !important;
  height: 50px !important;
  border-radius: 4px !important;
  background: transparent !important;
  color: var(--game-blue) !important;
  font-size: 22px !important;
  font-family: 'Inter', sans-serif;
  border: none !important;
  font-weight: 400;
  text-decoration: underline;
}

.button.link.newForm:hover {
  text-decoration: underline;
}
