.input {
  background: var(--new-gray-6);
  border: 1px solid var(--new-gray-6);
  box-shadow: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.input::placeholder {
  color: var(--new-gray-3);
}

.input:focus {
  border: 1px solid var(--new-blue);
}

.input.hasError,
.input.hasError:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.input[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.input.readOnly {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
}

.input[disabled]::placeholder,
.input.readOnly::placeholder {
  color: var(--new-gray-5);
}

.select {
  margin-right: 8px;
  max-width: 130px;
  margin-bottom: 0;
}

.wrapper {
  flex: 1 100%;
}

.inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
