.select {
  width: 100%;
}

.wrapper {
  position: relative;
  width: 100%;
}

.withIcon {
  padding-left: 25px !important;
}

.select :global .ant-select-selector {
  height: var(--input-height) !important;
  font-size: var(--input-fsz);
  color: var(--black);
  background: var(--snow);
  border-radius: 8px !important;
  outline: none;
  border: 1px solid var(--gray-input-preview) !important;
}

.select :global .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.select :global .ant-select-selector:active {
  border: 1px solid var(--cobalt);
}

.select :global .ant-select-selector:hover {
  border: 1px solid var(--game-blue);
}

.select :global .ant-select-selector:focus {
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
  background: var(--snow);
  color: var(--cobalt);
}

.withIcon {
  padding-left: 25px !important;
}

.icon {
  font-size: var(--input-fsz);
  color: var(--cobalt);
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 7px;
  transform: translateY(-50%);
}

.newForm.withIcon {
  padding-left: 36px !important;
}

.icon.newIcon {
  font-size: 16px !important;
  color: var(--gray-placeholder) !important;
  top: 0;
  z-index: 1;
  left: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: none;
}

.select.newForm :global .ant-select-selector {
  background: var(--snow);
  height: 50px !important;
  border-radius: 5px !important;
  outline: none !important;
  padding: 12px 16px !important;
  border: none !important;
  background: #f8f8f8 !important;
  font-size: 16px !important;
  font-weight: 400;
  color: #828b9a !important;
}

.select.newForm :global .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.select.newForm :global .ant-select-selector:active {
  border: none !important;
}

.select.newForm :global .ant-select-selector:hover {
  border: none !important;
}

.select.newForm :global .ant-select-selector:focus {
  border: none !important;
  box-shadow: var(--box-shadow) !important;
  background: var(--snow) !important;
  color: var(--cobalt) !important;
}
