.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  border-radius: 40px;
  border: 1px solid transparent;
}

.button.md {
  padding: 7px 16px;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  max-height: 32px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 16px;
  min-width: 106px;
}

.button.xs {
  padding: 4px 16px;
  max-height: 26px;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  min-width: 106px;
}

.button.xxs {
  padding: 4px 12px;
  max-height: 24px;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  min-width: 74px;
}

.button.lg {
  padding: 12px 16px;
  box-shadow: var(--btn-shadow);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  max-height: 40px;
  font-weight: 500;
  letter-spacing: -0.5px;
  min-width: 106px;
}

.button.lg.onlyIcon,
.button.md.onlyIcon,
.button.xs.onlyIcon {
  min-width: auto;
}

.icon {
  margin-right: 6px;
}

.backIcon {
  margin-left: 6px;
}

.button.onlyIcon .icon {
  margin-right: 0;
}

.spinner {
  -webkit-animation: spin 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  -moz-animation: spin 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  animation: spin 1s cubic-bezier(0.67, 0.56, 0.06, 0.765) infinite;
  align-items: center;
  justify-content: center;
}

.button.blue {
  background: var(--game-blue);
  color: var(--snow);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--game-blue);
}

.button.blue:hover:not(.loading) {
  background: var(--game-blue-hover);
  border: 1px solid var(--game-blue-hover);
}

.button.blue:active:not(.loading) {
  background: var(--game-blue-active);
  border: 1px solid var(--game-blue-active);
}

.button.yellow {
  background: var(--new-yellow);
  color: var(--snow);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--new-yellow);
}

.button.yellow:hover:not(.loading) {
  background: var(--new-yellow-hover);
  border: 1px solid var(--new-yellow-hover);
}

.button.yellow:active:not(.loading) {
  background: var(--new-yellow-active);
  border: 1px solid var(--new-yellow-active);
}

.button.yellow-ghost {
  color: var(--new-yellow);
  background: var(--snow);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--new-yellow);
}

.button.yellow-ghost:hover:not(.loading) {
  background: var(--new-yellow-hover);
  border: 1px solid var(--new-yellow-hover);
}

.button.yellow-ghost:active:not(.loading) {
  background: var(--new-yellow-active);
  color: var(--snow);
  border: 1px solid var(--new-yellow-active);
}

.loading {
  cursor: progress;
}

.button.ghost {
  background: var(--snow);
  color: var(--night);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--new-light-gray);
}

.button.ghost:hover:not(.loading) {
  background: var(--snow);
  color: var(--new-gray-500);
  border: 1px solid var(--new-light-gray);
}

.button.ghost:active:not(.loading) {
  color: var(--night);
  background: var(--new-light-gray-3);
  border: 1px solid var(--new-light-gray);
}

.button.blue-ghost,
.button.bluer-ghost {
  background: var(--snow);
  color: var(--game-blue);
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid var(--new-light-gray);
}

.button.blue-ghost:hover:not(.loading) {
  background: var(--snow);
  color: var(--new-gray-500);
  border: 1px solid var(--new-light-gray);
}

.button.blue-ghost:active:not(.loading) {
  color: var(--game-blue);
  background: var(--new-light-gray-3);
  border: 1px solid var(--new-light-gray);
}

.button.bluer-ghost {
  border: 1px solid var(--game-blue);
}

.button.bluer-ghost:hover:not(.loading) {
  background: var(--snow);
  color: var(--new-gray-500);
  border: 1px solid var(--new-light-gray);
}

.button.bluer-ghost:active:not(.loading) {
  color: var(--game-blue);
  background: var(--new-light-gray-3);
  border: 1px solid var(--new-light-gray);
}

.button.link {
  background: transparent;
  color: var(--new-blue);
  box-shadow: none;
  transition:
    background var(--animation),
    border var(--animation);
  border: 1px solid transparent;
  text-decoration: underline;
  box-sizing: content-box;
}

.button.link:hover:not(.loading) {
  background: transparent;
  color: var(--new-blue);
  border: 1px solid transparent;
}

.button.link:active:not(.loading) {
  color: var(--new-blue);
  background: transparent;
  border: 1px solid transparent;
}

.button[disabled],
.button[disabled]:hover,
.button[disabled]:active,
.button.blue[disabled],
.button.blue[disabled]:hover,
.button.blue[disabled]:active,
.button.yellow[disabled],
.button.yellow[disabled]:hover,
.button.yellow[disabled]:active,
.button.ghost[disabled],
.button.ghost[disabled]:hover,
.button.ghost[disabled]:active {
  background: var(--new-gray-4);
  cursor: not-allowed;
  border: 1px solid var(--new-gray-4);
  color: var(--new-gray-5);
  box-shadow: none;
}
.button.link[disabled],
.button.link[disabled]:hover,
.button.link[disabled]:active {
  background: transparent;
  cursor: not-allowed;
  border: 1px solid transparent;
  color: var(--new-gray-5);
  box-shadow: none;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
