.input {
  background: var(--new-gray-6);
  border: 1px solid var(--new-gray-6);
  box-shadow: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.input.tiny {
  height: 30px;
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translateY(-50%);
}

.postIcon {
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
}

.inputWrapper .input {
  padding: 7px 16px 7px 42px;
}

.inputWrapper.password .input {
  padding: 7px 42px;
}

.input::placeholder {
  color: var(--new-gray-3);
}

.input:focus {
  border: 1px solid var(--new-blue);
}

.input.hasError,
.input.hasError:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.input[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.input.readOnly {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
}

.input[disabled]::placeholder,
.input.readOnly::placeholder {
  color: var(--new-gray-5);
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
