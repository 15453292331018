.input {
  background: var(--new-gray-6);
  border: 1px solid var(--new-gray-6);
  box-shadow: none;
  height: 140px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.nonResizeAble {
  resize: none;
}

.input::placeholder {
  color: var(--new-gray-3);
}

.input:focus {
  border: 1px solid var(--new-blue);
}

.input.hasError,
.input.hasError:focus {
  border: 1px solid var(--new-red);
  color: var(--new-red);
}

.input[disabled] {
  border: 1px solid var(--new-light-gray);
  background: var(--snow);
  color: var(--new-gray-5);
  cursor: not-allowed;
}

.input[disabled]::placeholder {
  color: var(--new-gray-5);
}
