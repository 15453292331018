.input {
  background: var(--new-gray-6);
  border: 1px solid var(--new-gray-6);
  box-shadow: none;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  padding: 7px 16px 7px 36px;
  color: var(--new-night);
  transition: border var(--animation);
  outline: none;
}

.datepickerinput.tiny {
  height: 30px;
}

.datepickerinput {
  margin-bottom: 0;
  margin-right: 0;
}

.input[disabled]::placeholder,
.input.readOnly::placeholder {
  color: var(--new-gray-5);
}

.datepicker {
  font-family: 'Inter', sans-serif !important;
  border: 1px solid var(--new-light-gray-2) !important;
  border-radius: 5px !important;
  filter: drop-shadow(0px 8px 16px rgba(67, 86, 100, 0.02)) drop-shadow(0px 16px 32px rgba(67, 86, 100, 0.16));
}

.datepicker :global .react-datepicker__triangle:after {
  border-bottom-color: var(--snow) !important;
}

:global .react-datepicker-popper {
  z-index: 2 !important;
}

.datepicker :global .react-datepicker__day--today {
  background-color: var(--game-blue-hover) !important;
  border-radius: 5px !important;
  color: var(--snow);
}

.datepicker :global .react-datepicker__time-container {
  border-left: 1px solid var(--new-light-gray-2);
}

.datepicker :global .react-datepicker__day--selected {
  background-color: var(--game-blue) !important;
  border-radius: 5px !important;
  color: var(--snow);
}

.datepicker :global li.react-datepicker__time-list-item--selected {
  background-color: var(--game-blue) !important;
  border-radius: 0 !important;
  color: var(--snow);
}

.datepicker :global .react-datepicker__triangle:before {
  border-bottom-color: var(--new-light-gray-2) !important;
}

.datepicker :global .react-datepicker__header {
  background: var(--snow);
  border-bottom: 1px solid var(--new-light-gray-2) !important;
}

.datepickerinput.error input {
  border: 1px solid var(--new-red) !important;
}
