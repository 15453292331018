.hint {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
}

.iconWrapper {
  color: var(--game-yellow);
  margin-right: 6px;
  font-size: 14px;
}

.icon {
  margin-right: 12px;
}

.whiteHint {
  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--new-dark-gray);
  font-weight: 400;
  line-height: 17px;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.5px;
}

.whiteHint.black .label {
  color: var(--night);
}

.iconWrapper.red {
  color: var(--red);
}

@media all and (max-width: 1599px) {
  .iconWrapper {
    font-size: 10px;
  }
}
