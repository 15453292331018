.uploader {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid var(--border-gray);
  display: inline-block;
}

.uploader.disabled,
.uploader.empty.disabled,
.uploader.empty.disabled:hover,
.uploader.empty.disabled:focus,
.uploader.empty.disabled:active {
  cursor: not-allowed;
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
  box-shadow: none;
}

.uploader.empty {
  color: var(--snow);
  display: flex;
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
}

.modalFooter {
  display: flex;
  margin-top: 12px;
  justify-content: flex-end;
}

.modalFooter button:not(:last-child) {
  margin-right: 8px;
}

.uploader.empty:hover {
  background: var(--dark-game-blue);
  border: 1px solid var(--dark-game-blue);
}

.uploader.empty[disabled] {
  background: var(--blue-line);
  border: 1px solid var(--blue-line);
  color: var(--gray-file-upload);
}

.uploader.empty:focus,
.uploader.empty:active {
  background: var(--game-blue);
  border: 1px solid var(--game-blue);
  box-shadow: var(--box-shadow);
}

.imagePreview {
  max-width: 300px;
  max-height: 300px;
  margin-bottom: 20px;
}

.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.inputFileBtn {
  position: relative !important;
  cursor: pointer !important;
}

.dndArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  min-height: 180px;
  border-radius: 10px;
  background: var(--gray-file-upload);
  border: 1px dashed var(--gray-font);
}

.modalWrapper {
}

.dndArea.canDrop {
  border: 1px solid var(--green);
  background: var(--green-opacity);
}

.iconWrapper {
  font-size: 48px;
  color: var(--green);
}

.iconFileWrapper {
  font-size: 48px;
  color: var(--game-blue);
}

.fileName {
  font-size: var(--input-fsz);
  text-align: center;
  margin-bottom: var(--input-padding);
  color: var(--cobalt);
}

.withFileWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.uploaderButton {
  margin-bottom: var(--input-padding);
  height: var(--input-height);
  max-width: 180px;
}

.valueText {
  width: 100%;
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploaderButtonBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 10px;
  text-align: center;
}

.uploaderButtonIcon {
  font-size: 16px;
  color: inherit;
  margin-bottom: var(--input-padding);
}

.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  position: absolute;
}

.inputFileBtn {
  position: relative !important;
  cursor: pointer !important;
}

.uploader .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}
