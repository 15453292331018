.link {
  margin-left: 8px;
}

.body {
  text-align: center;
  padding-top: 20px;
}

.text {
  margin-bottom: 16px;
}
