.wrapper {
  position: relative;
  width: 130px;
  height: var(--input-height);
}

.value {
  background: var(--snow);
  border-radius: 8px;
  color: var(--cobalt);
  height: var(--input-height);
  font-size: var(--input-fsz);
  padding: 4px 20px 4px 8px;
  border: 1px solid var(--gray-input-preview);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.valueText {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  position: absolute;
  font-size: 9px;
  color: var(--cobalt);
  top: 50%;
  right: 8px;
  transform: translate3d(-50%, -50%, 0);
}

.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: var(--snow);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(119, 119, 119, 0.25);
}

.inputWrapper {
  position: relative;
  border-bottom: 1px solid var(--gray-file-upload);
}

.inputWrapper input {
  width: 100%;
  padding: 4px 8px 4px 30px;
  color: var(--cobalt);
  font-size: var(--input-fsz);
  border: none;
  outline: none;
  border-radius: 10px 10px 0 0;
}

.inputWrapper .inputIcon {
  position: absolute;
  font-size: 12px;
  color: var(--cobalt);
  top: 50%;
  left: 15px;
  transform: translate3d(-50%, -50%, 0);
}

.value.disabled {
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
  color: var(--light-gray-font);
  cursor: not-allowed;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  max-height: 290px;
  overflow-y: auto;
}

.section {
  display: flex;
  flex-direction: column;
}

.label {
  padding: 9px 8px;
  font-size: var(--input-fsz);
  color: var(--cobalt);
  font-weight: bold;
  border-bottom: 1px solid var(--gray-file-upload);
}

.list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.list li {
  color: var(--cobalt);
  cursor: pointer;
  width: 100%;
  padding: 9px 8px 9px 16px;
  border-bottom: 1px solid var(--gray-file-upload);
}

.list li:hover {
  background: var(--gray-file-upload);
}
