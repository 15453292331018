.wrapper :global .ant-switch-checked {
  background: var(--game-blue);
}

.wrapper .label {
  color: var(--black);
  margin-right: 8px;
}

.wrapper.bad :global .ant-switch-checked {
  background: var(--red);
}

.wrapper.good :global .ant-switch {
  background: var(--game-green);
}
