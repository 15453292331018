.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--input-padding);
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wrapper :global .ant-input,
.wrapper :global .ant-picker {
  background: var(--snow);
  border-radius: 8px;
  color: var(--cobalt);
  height: var(--input-height);
  font-size: var(--input-fsz);
  padding: 4px 8px;
  border: 1px solid var(--gray-input-preview);
}

.wrapper :global textarea.ant-input {
  min-height: 40px;
  resize: vertical;
}

.wrapper :global .ant-switch {
  height: var(--input-height);
  font-size: var(--input-fsz);
}

.wrapper :global .ant-switch .ant-switch-handle {
  width: 16px;
  height: 16px;
  top: 50%;
  margin-top: -8px;
}

.newFormWrapper {
  height: 50px;
}
.newFormWrapper > div {
  height: auto !important;
}
.wrapper.withoutBorder {
  margin-bottom: 0;
}

.wrapper :global .ant-picker-panel-container {
  border-radius: 8px;
}

.wrapper :global .ant-input:active,
.wrapper :global .ant-picker:active,
.wrapper :global .ant-input-number-input:active {
  border: 1px solid var(--cobalt);
}

.wrapper :global .ant-input:hover,
.wrapper :global .ant-input-number-input:hover,
.wrapper :global .ant-picker:hover {
  border: 1px solid var(--game-blue);
}

.wrapper :global .ant-input:focus,
.wrapper :global .ant-input-number-input:focus,
.wrapper :global .ant-picker:focus {
  border: 1px solid var(--button-box-shadow-color);
  box-shadow: var(--box-shadow);
  background: var(--snow);
  color: var(--cobalt);
}

.wrapper :global .ant-input[disabled],
.wrapper :global .ant-input-number-input[disabled] {
  border: 1px solid var(--border-gray);
  background: var(--gray-file-upload);
  color: var(--light-gray-font);
}

.label {
  color: var(--black);
  margin-right: 8px;
  white-space: nowrap;
}

.label.disabled {
  color: var(--light-gray-font);
}

.errors {
  display: flex;
  margin-top: 5px;
  flex-direction: column;
}

.errors.newForm {
  margin-bottom: 0;
  margin-top: 5px;
  margin-left: 5px;
}

.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-bottom: 5px;
}

.newForm .error {
  margin-bottom: 0;
}
