.popoverTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 14px;
}

.modal > div {
  width: 600px;
}

.modal h1 {
  font-size: 26px !important;
}

.modal > div > div {
  width: 70%;
}

.modal > div > div > div {
  flex-direction: row-reverse;
  justify-content: center;
  width: 80%;
}

.modal button {
  margin: unset;
}
